import { IGroup } from '../../../types'

export const AIPlatform: IGroup = {
  icon: 'icon-rengongzhinengdanao',
  name: 'AI 平台',
  en_name: 'AI platform',
  order: 1,
  children: [
    {
      icon: 'https://origin.nextway.top/favicon.ico',
      name: 'EasyChat',
      en_name: 'SAM Geo',
      description:
        'EasyChat 是一个基于文本的聊天系统，旨在为用户提供一个简单、方便的交流平台。它可以用于各种场景，包括社交交流、客户服务、教育等。EasyChat 的主要功能包括即时消息发送和接收、创建群组聊天、个人信息管理、消息通知等。通过 EasyChat，用户可以轻松地与朋友、同事或客户进行实时交流，并且可以根据自己的需求进行个性化设置和管理。',
      en_description:
        'EasyChat is a text-based chat system designed to provide users with a simple and convenient communication platform. It can be used in various scenarios, including social interactions, customer service, education, etc. The main features of EasyChat include instant messaging, group chat creation, personal information management, message notifications, etc. Through EasyChat, users can easily communicate in real-time with friends, colleagues, or clients, and can personalize settings and management according to their needs.',
      site_url: 'https://origin.nextway.top/',
      order: 31,
      tags: ['Spatial Analysis', 'Mapping', 'GIS', 'Remote Sensing']
    },
    {
      icon: 'https://origin.eqing.tech/favicon.ico',
      name: 'EasyGPT',
      en_name: 'PaddlePaddle AI Studio',
      description:
        'EasyChat 是一个基于文本的聊天系统，旨在为用户提供一个简单、方便的交流平台。它可以用于各种场景，包括社交交流、客户服务、教育等。EasyChat 的主要功能包括即时消息发送和接收、创建群组聊天、个人信息管理、消息通知等。通过 EasyChat，用户可以轻松地与朋友、同事或客户进行实时交流，并且可以根据自己的需求进行个性化设置和管理。',
      en_description:
        'EasyChat is a text-based chat system designed to provide users with a simple and convenient communication platform. It can be used in various scenarios, including social interactions, customer service, education, etc. The main features of EasyChat include instant messaging, group chat creation, personal information management, message notifications, etc. Through EasyChat, users can easily communicate in real-time with friends, colleagues, or clients, and can personalize settings and management according to their needs.',
      site_url: 'https://origin.eqing.tech/',
      order: 2,
      tags: [
        'AI Development',
        'Machine Learning',
        'Deep Learning',
        'Artificial Intelligence'
      ]
    },
  ]
}
